import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const getShortlistedInternsUrl = '/host-company/browse-intern/get-shortlist-interns';
const shortListInternRemoveURL = 'host-company/browse-intern/delete-shortlist-intern';
const shortListInternSaveURL = 'host-company/browse-intern/save-shortlist-intern';

export const ShortListInternApi = createApi({
  reducerPath: 'shortListInternApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ShortListIntern'],
  endpoints: (builder) => ({
    getShortlistedInterns: builder.query({
      query: (params) => ({
        url: `${getShortlistedInternsUrl}?${new URLSearchParams(params).toString()}`,
        method: 'POST',
        body: {},
      }),
      providesTags: ['ShortListIntern'],
    }),
    saveShortListIntern: builder.mutation({
      query: (body) => ({ url: shortListInternSaveURL, method: 'POST', body }),
      invalidatesTags: ['ShortListIntern'],
    }),
    removeShortListIntern: builder.mutation({
      query: (body) => ({ url: shortListInternRemoveURL, method: 'POST', body }),
      invalidatesTags: ['ShortListIntern'],
    }),
  }),
});

export const {
  useGetShortlistedInternsQuery,
  useSaveShortListInternMutation,
  useRemoveShortListInternMutation,
} = ShortListInternApi;

export default ShortListInternApi.reducer;

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';
import { objectToQueryString } from '../../../../Utils/Common/utils';

const getHostCompanyUsersUrl = '/host-company/pocs';
const getJobFunctionsUrl = '/common-services/job-functions';
const getInternshipPlansUrl = '/host-company/internship-plan';
const getInternshipPlansByIdUrl = '/host-company/internship-plan/get-details';
const getSkillsUrl = '/common-services/career-skill/list';
const getCareerFieldsUrl = '/common-services/career-field/get-list';
const createIoUrl = '/host-company/internship-plan/v2/create';
const createDraftIRUrl = 'host-company/internship-plan/create-draft';
const updateIoStatusUrl = '/host-company/internship-plan/update-status';
const getInternshipPlansByIdOnInternSideUrl = '/common-services/get-io-details';
const getInternsSuggestionsUrl = '/host-company/browse/candidates/v1/suggestions';
const hideSuggestionsUrl = '/host-company/dashboard/career-field/v1/hide';

export const InternshipOpportunityApi = createApi({
  reducerPath: 'internshipOpportunity',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['getInternshipPlans', 'getInternshipPlansById', 'suggestedInterns'],
  endpoints: (builder) => ({
    getHostCompanyUsers: builder.query({
      query: (searchParams) => {
        const params = new URLSearchParams(searchParams);
        const url = `${getHostCompanyUsersUrl}?${params}`;
        return { url, method: 'GET' };
      },
    }),
    getInternshipPlansById: builder.query({
      query: (ioId) => {
        const url = `${getInternshipPlansByIdUrl}/${ioId}`;
        return { url, method: 'GET' };
      },
      providesTags: ['getInternshipPlansById'],
    }),
    getInternshipPlans: builder.query({
      query: (params) => {
        let queryString = objectToQueryString(params);
        let updatedUrl = queryString
          ? `${getInternshipPlansUrl}?${queryString}`
          : getInternshipPlansUrl;
        return { url: updatedUrl, method: 'GET' };
      },
      providesTags: ['getInternshipPlans'],
    }),
    getInternSuggestions: builder.query({
      query: (params) => {
        return {
          url: `${getInternsSuggestionsUrl}?${new URLSearchParams(params).toString()}`,
          method: 'GET',
          body: null,
        };
      },
      providesTags: ['suggestedInterns'],
    }),
    getCareerFields: builder.query({
      query: () => {
        return { url: getCareerFieldsUrl, method: 'GET' };
      },
    }),
    getSkills: builder.query({
      query: () => {
        return { url: getSkillsUrl, method: 'GET' };
      },
    }),
    getJobFunctions: builder.query({
      query: (body) => {
        let queryString = objectToQueryString(body);
        let updatedUrl = queryString ? `${getJobFunctionsUrl}?${queryString}` : getJobFunctionsUrl;
        return { url: updatedUrl, method: 'GET' };
      },
    }),
    createInternshipOpportunity: builder.mutation({
      query: (body) => ({ url: createIoUrl, method: 'POST', body }),
    }),
    createDraftIR: builder.mutation({
      query: (body) => ({
        url: createDraftIRUrl,
        method: 'POST',
        body,
      }),
    }),
    updateInternshipOpportunityStatus: builder.mutation({
      query: (body) => ({ url: updateIoStatusUrl, method: 'POST', body }),
      invalidatesTags: ['getInternshipPlansById', 'suggestedInterns', 'getInternshipPlans'],
    }),
    hideSuggestions: builder.mutation({
      query: (body) => ({ url: hideSuggestionsUrl, method: 'POST', body }),
      invalidatesTags: ['suggestedInterns'],
    }),
    getInternshipPlansByIdOnInternSide: builder.mutation({
      query: (body) => ({ url: getInternshipPlansByIdOnInternSideUrl, method: 'POST', body }),
    }),
  }),
});

export const {
  useGetHostCompanyUsersQuery,
  useGetJobFunctionsQuery,
  useGetInternshipPlansByIdQuery,
  useGetInternshipPlansQuery,
  useGetCareerFieldsQuery,
  useGetSkillsQuery,
  useCreateInternshipOpportunityMutation,
  useUpdateInternshipOpportunityStatusMutation,
  useHideSuggestionsMutation,
  useCreateDraftIRMutation,
  useGetInternshipPlansByIdOnInternSideMutation,
  useGetInternSuggestionsQuery,
  usePrefetch,
} = InternshipOpportunityApi;
export default InternshipOpportunityApi.reducer;

import React, { useState, createContext, useMemo } from 'react';

// Define the context type
export interface AppContextType {
  setBanner: React.Dispatch<
    React.SetStateAction<{
      borderColor: string;
      background: string;
      text: string;
      padding?: string;
    }>
  >;
  banner: {
    borderColor: string;
    background: string;
    text: string;
    padding?: string;
  };
  showSidebar: boolean;
  userType: string;
  setShowSidebar: (value: boolean) => void;
}

export const AppContext = createContext<AppContextType | undefined>(null);

export function AppContextProvider({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [userType, setUserType] = useState('');
  const [banner, setBanner] = useState({ borderColor: '', background: '', text: '' });

  const providerValue = useMemo(
    () => ({
      showSidebar,
      setShowSidebar,
      showHeader,
      setShowHeader,
      banner,
      setBanner,
      userType,
      setUserType,
    }),
    [
      showSidebar,
      setShowSidebar,
      showHeader,
      setShowHeader,
      banner,
      setBanner,
      userType,
      setUserType,
    ],
  );

  return <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>;
}
